import React, { useEffect } from "react"
import Layout, { MyLocationEnum } from '../components/Layout'
import SEO, { MetaOG } from "../components/Seo"

export default ({ location }) => {
  
  const seo_og: MetaOG = {
    title: "Velkomin",
    description: "ConceptEvents",
  }

  return (
    <Layout location={location} myLocation={MyLocationEnum.Lobby}>
      <SEO
        title="Velkomin"
        description="ConceptEvents"
        og={seo_og}
      />
    </Layout>
  )
}